// 參考 https://github.com/vercel/next.js/blob/canary/packages/third-parties/src/google/gtm.tsx
export const sendAppierEvent = (event, data) => {
  if (window.appier) {
    window.appier('event', event, data);
  } else {
    console.warn('Appier 未載入');
  }
};

export const sendAppierIdentify = (data) => {
  if (window.appier) {
    window.appier('identify', data);
  } else {
    console.warn('Appier 未載入');
  }
};
